import * as React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { hasSubscription, getMySubscriptionUrl } from '../services/stripe-service';
import { Location } from '../models';
import { Link } from 'gatsby';
import { A } from '../components/a';

export default function AutoSubscribe({ location }: { location: Location}) {
  const activeClassName = 'current_page';
  const { getAccessTokenSilently } = useAuth0();
  const [subscribed, setSubscribed] = React.useState(false);
  const [accessToken, setAccessToken] = React.useState('');

  const searchParams = new URLSearchParams(location.search)
  // const priceId = searchParams.get('price_id') || process.env.GATSBY_STRIPE_PRO_MONTHLY_PRICE_ID || 'missing-price-id'
  const priceId = searchParams.get('price_id') || process.env.GATSBY_STRIPE_PRO_ANNUAL_PRICE_ID || 'missing-price-id'
  const subscribe = async () => {
    console.log('subscribe');
    const token = await getAccessTokenSilently({
      audience: process.env.GATSBY_AUTH0_AUDIENCE,
      scope: process.env.GATSBY_AUTH0_SCOPE,
    });
    setAccessToken(token);

    console.log('getAccessTokenSilently executed', token);

    const alreadySubscribed = await hasSubscription(token);
    setSubscribed(alreadySubscribed);

    if (alreadySubscribed) {
      return;
    }

    const res = await fetch(process.env.GATSBY_AZURE_BUY_FUNCTION_URL!, {
      method: 'POST',
      headers: {
        authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        subscriptionId: priceId
      }),
    });
    const json = await res.json();

    console.log('Azure function executed', json);
    // The response is a checkout session
    window.location.assign(json.url);
  };

  const redirectToMySubscription = React.useCallback(async () => {

    const redirectUrl = await getMySubscriptionUrl(accessToken);
    window.location.assign(redirectUrl);
  }, [accessToken]);

  React.useEffect(() => {
    subscribe().then();
  }, []);

  return (
    <>
      {!subscribed && 
        <div style={{ marginTop: '150px', textAlign: 'center', fontSize: '2rem' }}>
          Please wait...
        </div>
      }
      {subscribed && 
        <div style={{ marginTop: '150px', textAlign: 'center', fontSize: '2rem' }}>
          <p>You are already subscribed!</p>
          <p style={{ paddingTop: '25px' }}>
          <A onClick={() => redirectToMySubscription()} 
            style={{ color: '#007bff', cursor: 'pointer'}}> Manage Plan
          </A> 
          </p>
        </div>
      }
    </>
  );
}
